import * as yup from "yup";

//hospital Branch
export const hospitaBranchDialogValidation = yup.object().shape({
    jobLocationHospitalName:yup.string().required("Job Location Hospital Name is required"),
    jobLocationBranchName:yup.string().required("Job Location Brach Name is required"),
    company:yup.string().required("Company is required"),
    corporate:yup.string().required("Corporate is required"),
    reverseCharges:yup.string().required()
});


export const hospitalBranchFormValidation=yup.object().shape({
    jobLocationHospitalName:yup.string().required("Job Location Hospital Name is required")
})
export const DepartmentDialogValidation=yup.object().shape({
    company:yup.string().required("company is required"),
    branch:yup.string().required("branch is required"),
    departmentType:yup.string().required("department type is required")
})



export const CompanyValidation=yup.object().shape({
    corporateName:yup.string().required(),
    companies:yup.string().required(),
    companyType:yup.string().required()
})


//Corporate
export const corporateDialogValidation=yup.object().shape({
    name:yup.string().required("Corporate Name is Required"),
    corporateType:yup.string().required("Corporate Type  is Required"),
    logoLocation:yup.string().required("Logo Location is Required")
})

export const ApplicationDialogValidation = yup.object().shape({
    organization: yup.string().required("Organization is required"),
professional: yup.string().required("Profession is required"),
job: yup.string().required("Job is required"),
state: yup.string().required("State is required")
  });


export const ratesValidation=yup.object().shape({ 
    minRate:yup.string().required("Min Rate is required"), 
    marginHospital:yup.string().required("Margin Hospital is Required"), 
    marginCandidate:yup.string().required("Margin Candidate is required"),
    profession:yup.string().required(),
    qualification:yup.string().required(),
    department:yup.string().required(),
    branch:yup.string().required(),
    specialization:yup.string().required(),
    experience:yup.string().required(),

 })

 export const monetizationValidation=yup.object().shape({ 
    city:yup.string().required(),
    role:yup.string().required(),
    department:yup.string().required(),
    specialization:yup.string().required(),
    numExperience:yup.string().required(),
    valueType:yup.string().required(),
    value:yup.string().required(),
 })

 export const videoValidation=yup.object().shape({ 
    index:yup.string().required(),
    title:yup.string().required(),
    url:yup.string().required(),
 })


//  export const permanentJobCreateValidSchema = yup.object().shape({
//     department: yup.string().required("Department is required"),
//     // "salary.type": yup.string().required("Salary Type is Required"),
//     // "salary.min": yup.string().required("Salary Min is Required"),
//     // "salary.max": yup.string().required("Salary Max is Required"),
//     // "salary.currency": yup.string().required("Salary Currency is Required"),
    
//   });


  export const permanentJobCreateValidations = yup.object().shape({
    department: yup.string().required("Department is required"),
    // "salary.type": yup.string().required("Salary Type is Required"),
    // "salary.min": yup.string().required("Salary Min is Required"),
    // "salary.max": yup.string().required("Salary Max is Required"),
    // "salary.currency": yup.string().required("Salary Currency is Required"),
  });